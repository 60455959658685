.w-0{
  width: 0;
}
.w-5{
  width: 5%;
}
.w-10{
  width: 10%;
}
.w-15{
  width: 15%;
}
.w-20{
  width: 20%;
}
.w-25{
  width: 25%;
}
.w-30{
  width: 30%;
}
.w-33{
  width: 33%;
}
.w-35{
  width: 35%;
}
.w-40{
  width: 40%;
}
.w-45{
  width: 45%;
}
.w-55{
 width: 55%;
}
.w-50 {
  width: 50%;
}
.w-60{
  width: 60%;
}
.w-65{
  width: 65%;
}
.w-70{
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-80{
  width: 80%;
}
.w-90{
  width: 90%;
}
.w-95{
  width: 95%;
}
.w-100 {
  width: 100%;
}

.max-w-50{
  max-width: 50%;
}

.max-w-100{
  max-width: 100%;
}

.h-100 {
  height: 100%;
}

.max-h-50{
  max-height: 50%;
}

.max-h-100{
  max-height: 100%;
}

// margin classes

.m-0{
  margin: 0;
}

.m-1{
  margin: 0.063rem;
}

.m-2{
  margin: 0.125rem;
}

.m-3{
  margin: 0.189rem;
}

.m-4{
  margin: 0.25rem;
}

.m-5{
  margin: 0.3rem;
}
.m-10{
  margin: 0.625rem;
}

.m-15{
margin: 0.938rem;
}

.m-20{
  margin: 1.25rem;
}

// margin x-axis

.mx-5{
  margin-left: 0.313rem;
  margin-right: 0.313rem;
}

.mx-10{
margin-left: 0.625rem;
margin-right: 0.625rem;

}

.mx-15{
margin-left: 0.938rem;
margin-right: 0.938rem;
}

.mx-20{
margin-left: 1.25rem;
margin-right: 1.25rem;
}

.mx-30{
margin-left: 1.875rem;
margin-right: 1.875rem;
}

// margin y-axis

.my-4{
margin-top: 0.25rem;
margin-bottom: 0.25rem;
}

.my-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-10{
margin-top: 0.625rem;
margin-bottom: 0.625rem;
}

.my-12{
margin-top: 0.75rem;
margin-bottom: 0.75rem;
}

.my-15{
margin-top: 0.938rem;
margin-bottom: 0.938rem;
}

.my-20{
margin-top: 1.25rem;
margin-bottom: 1.25rem;
}
.my-30{
margin-top: 1.875rem;
margin-bottom: 1.875rem;
}

// margin top scss

.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: 0.125rem;
}

.mt-3{
  margin-top: 0.188rem;
}

.mt-4 {
  margin-top: 0.25rem;
}

.mt-5 {
  margin-top: 0.313rem;
}
.mt-7 {
  margin-top: 0.438rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.mt-10 {
  margin-top: 0.625rem;
}

.mt-11 {
  margin-top: 0.688rem;
}

.mt-12{
  margin-top: 0.75rem;
}

.mt-15{
  margin-top: 0.938rem;
}

.mt-20{
  margin-top: 1.25rem;
}

.mt-25{
margin-top: 1.563rem;
}

.mt-30{
  margin-top: 1.875rem;
}
.mt-35{
  margin-top: 2.1875rem;
}

.mt-50{
  margin-top:3.125rem;
}

.mt-70{
margin-top: 4.375rem;
}

.mt-100{
margin-top: 6.25rem;
}
// margin bottom scss
.mb-0{
  margin-bottom: 0;
}

.mb-3{
  margin-bottom: 0.188rem;
}
.mb-4{
  margin-top: 0.25rem;
}
.mb-5{
  margin-bottom: 0.313rem;
}

.mb-6{
  margin-bottom: 0.375rem;
}
.mb-8{
  margin-bottom: 0.5rem;
}
.mb-10{
  margin-bottom: 0.625rem;
}

.mb-12{
  margin-bottom: 0.75rem;
}

.mb-14{
  margin-bottom: 0.875rem;
}
.mb-15{
  margin-bottom: 0.938rem;
}

.mb-16{
  margin-bottom: 1rem;
}

.mb-20{
  margin-bottom: 1.25rem;
}

.mb-25{
margin-bottom: 1.563rem;
}

.mb-30{
  margin-bottom: 1.875rem;
}
.mb-35{
  margin-bottom: 2.1875rem;
}
.mb-40{
  margin-bottom: 2.5rem;
}
.mb-42{
  margin-bottom:2.625rem;
}
.mb-50{
  margin-bottom: 3.125rem;
}
.mb-52{
  margin-bottom: 3.25rem;
}
.mb-65{
  margin-bottom: 4.063rem;
}
// margin left scss

.ml-0 {
  margin-left: 0;
}

.ml-3 {
  margin-left: 0.188rem;
}

.ml-5 {
  margin-left: 0.313rem;
}

.ml-8 {
  margin-left: 0.5rem;
}

.ml-10 {
  margin-left: 0.625rem;
}

.ml-12 {
  margin-left: 0.75rem;
}

.ml-15{
  margin-left: 0.938rem;
}

.ml-20{
  margin-left: 1.25rem;
}

.ml-30{
  margin-left: 1.875rem;
}
.ml-40{
  margin-left: 2.5rem;
}

// margin right scss

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 0.063rem;
}

.mr-3 {
  margin-right: 0.188rem;
}

.mr-5 {
  margin-right: 0.313rem;
}

.mr-8 {
  margin-right: 0.5rem;
}

.mr-10 {
  margin-right: 0.625rem;
}
.mr-12 {
  margin-right: 0.75rem;
}
.mr-15 {
  margin-right: 0.9375rem;
}

.mr-20 {
  margin-right: 1.25rem;
}
.mr-25{
  margin-right: 1.563rem;
}
.mb-28{
  margin-bottom:1.75rem;
}
.mr-30{
  margin-right:1.875rem;
}
.mr-40{
  margin-right: 2.5rem;
}
.mr-50{
  margin-right: 3.5rem;
}
// padding scss

.p-0{
  padding: 0;
}

.p-1{
  padding: 0.063rem;
}

.p-2{
  padding: 0.125rem;
}

.p-3{
  padding: 0.188rem;
}

.p-4{
  padding: 0.25rem;
}

.p-5{
  padding:0.313rem;
}

.p-6{
  padding: 0.375rem;
}
.p-8{
  padding: 0.5rem;
}
.p-10{
  padding: 0.625rem;
}

.p-15{
  padding: 0.938rem;
}

.p-20{
  padding: 1.25rem;
}

.p-30{
padding: 1.875rem;
}
.p-40{
  padding:2.5rem;
  }
  

.p-50{
padding: 3.125rem;
}

// padding x-axis

.px-5{
padding-left: 0.313rem;
padding-right: 0.313rem;
}

.px-6{
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.px-10{
padding-left:0.625rem;
padding-right:0.625rem;
}

.px-15{
padding-left: 0.938rem;
padding-right: 0.938rem;
}

.px-20{
padding-left: 1.25rem;
padding-right: 1.25rem;
}
.px-25{
  padding-left: 1.5rem;
padding-right: 1.5rem;
}
.px-30{
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.px-40{
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-50{
padding-left: 3.125rem;
padding-right: 3.125rem;
}

.px-72{
  padding-left: 4.5rem;
  padding-right: 4.5rem;
  }


// padding y-axis
.py-1{
padding-top: 0.063rem;
padding-bottom: 0.063rem;
}

.py-2{
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.py-4{
padding-top: 0.25rem;
padding-bottom: 0.25rem;
}

.py-5{
padding-top: 0.313rem;
padding-bottom:0.313rem;
}

.py-8{
padding-top: 0.5rem;
padding-bottom: 0.5rem;
}

.py-9{
  padding-top: 0.563rem;
  padding-bottom: 0.563rem;
}

.py-10{
padding-top: 0.625rem;
padding-bottom: 0.625rem;
}

.py-15{
  padding-top: 0.938rem;
  padding-bottom: 0.938rem;
}

.py-16{
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.py-20{
padding-top: 1.25rem;
padding-bottom: 1.25rem;
}

.py-30{
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  }
.py-50{
padding-top: 3.125rem;
padding-bottom: 3.125rem;
}

// padding top scss

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top:0.063rem;
}

.pt-3{
  padding-top:0.188rem;
}

.pt-5 {
  padding-top:0.313rem;
}

.pt-6 {
  padding-top: 0.375rem;
}

.pt-7 {
  padding-top:0.438rem;
}

.pt-8 {
  padding-top: 0.5rem;
}

.pt-9 {
  padding-top: 0.563rem;
}

.pt-10 {
  padding-top:0.625rem;
}

.pt-11{
  padding-top:0.688rem;
}

.pt-12{
  padding-top:0.75rem;
}

.pt-15{
  padding-top: 0.938rem;
}
.pt-16{
  padding-top: 1rem;
}

.pt-20{
  padding-top: 1.25rem;
}
.pt-25{
  padding-top: 1.5rem;
}
.pt-30{
  padding-top: 1.875rem;
}
.pt-40{
  padding-top: 3.125rem;
}
.pt-50{
padding-top: 2.5rem;
}
.pt-60{
  padding-top: 3.75rem;
}
.pt-75{
  padding-top: 4.688rem;
}
.pt-100{
padding-top: 6.25rem;
}

// padding bottom scss

.pb-0{
  padding-bottom: 0;
}

.pb-3{
  padding-bottom: 0.188rem;
}

.pb-5{
  padding-bottom:0.313rem;
}

.pb-8{
  padding-bottom: 0.5rem;
}

.pb-9{
  padding-bottom: 0.563rem;
}

.pb-10{
  padding-bottom: 0.625rem;
}

.pb-11{
  padding-bottom:0.688rem;
}

.pb-12{
  padding-bottom: 0.75rem;
}

.pb-15{
  padding-bottom:0.938rem;
}

.pb-20{
  padding-bottom: 1.25rem;
}

.pb-24{
  padding-bottom: 1.5rem;
}

.pb-30{
  padding-bottom: 1.875rem;
}

.pb-34{
  padding-bottom: 2.125rem;
}

.pb-50{
  padding-bottom: 3.125rem;
  }

// padding left scss

.pl-0 {
  padding-left: 0;
}

.pl-3 {
  padding-left: 0.188rem;
}

.pl-5 {
  padding-left:0.313rem;
}

.pl-8 {
  padding-left: 0.5rem;
}

.pl-10 {
  padding-left: 0.625rem;
}

.pl-12 {
  padding-left: 0.75rem;
}

.pl-16 {
  padding-left: 1rem;
}

.pl-15 {
  padding-left: 0.938rem;
}

.pl-20{
  padding-left: 1.25rem;
}

.pl-30{
  padding-left:1.875rem;
}
.pl-40{
  padding-left: 2.5rem;
}
.pl-45{
  padding-left: 2.813rem;
}
.pl-46{
  padding-left: 2.875rem;
}
.pl-70{
  padding-left: 4.375rem;
}
// padding right scss

.pr-0 {
  padding-right: 0;
}

.pr-3 {
  padding-right: 0.188rem;
}

.pr-5 {
  padding-right: 0.313rem;
}

.pr-8 {
  padding-right: 0.5rem;
}

.pr-10 {
  padding-right: 0.625rem;
}

.pr-12 {
  padding-right: 0.75rem;
}

.pr-14 {
  padding-right: 0.875rem;
}

.pr-16 {
  padding-right: 1rem;
}

.pr-15 {
  padding-right: 0.938rem;
}

.pr-20{
  padding-right: 1.25rem;
}

.pr-24{
  padding-right: 1.5rem;
}

.pr-30{
  padding-right: 1.875rem;
}

.pr-50{
  padding-right: 3.125rem;
}

.pr-70{
  padding-right: 4.375rem;
}