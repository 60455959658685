@mixin avatar($wd, $ht, $mr, $lh){
    width: $wd;
    height: $ht;
    margin-right: $mr;
    margin-left: 0;
    position: relative;
    text-align: center;
    line-height: $lh;
    color: #fff;
    font-weight: 400;
    background-color: #273138;
}

// Patient Avatar
.default-avatar{
    @include avatar(40px, 40px, 5px,40px);
    border-radius: 22px;
}

.small-avatar{
    @include avatar(32px, 32px, 8px,32px);
    border-radius:20px;
   margin-right: 5px;
}