body{
  background: #fff;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
.link{
  text-decoration: none;
  color: var(--ion-button-bg-color);
}
.content{
  height: calc(100vh - 65px);
  height: calc(100dvh - 65px);
  height: calc(100svh - 65px);
}
.header-md::after {
  background-image: none;
}
.editInfo_popup{
  .header{
    display: none;
  }
  .patientInfoMobileHeading{
    display: none;
  }
  .mb-35{
    margin-bottom: 1.563rem;
  }
  .desktop_heading{
    display: block !important;
  }
}


//scrollbar

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  margin-top:3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180) !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(136, 136, 136) !important;
}
ion-grid{
  padding: 0px;
}
// .mid-comment-border {
//     border-left: 1px solid var(--ion-color-gray20);
//     border-right: 1px solid var(--ion-color-gray20);
//     }
.mid-header-section{
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.d-inline-block{
  display: inline-block;
}

// Confirm patient header
.confirm_patient_main{
  .login-verify-hide,
  .patient-nm{
      display: none !important;
  }
  .mid-header-section{
    align-items: center;
  }
}

.cursor{
  cursor: pointer;
}


// Search Bar
.searchbar-input-container.sc-ion-searchbar-md,
.searchbar-input.sc-ion-searchbar-ios{
  border: 1px solid var(--ion-color-gray40);
  border-radius: 20px;
}
.searchbar-input.sc-ion-searchbar-md{
  height: 36px;
  padding-inline-start: 42px;
}
.searchbar-search-icon.sc-ion-searchbar-ios{
  height: 36px;
  inset-inline-start: 12px;
}
.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios{
  padding-inline-start: 42px;
}
.sc-ion-searchbar-ios-h{
  height: 40px;
}
.searchbar-search-icon.sc-ion-searchbar-md{
  top:7px;
}
ion-searchbar[mode='q'] {
  --background: #fff; //	Background of the searchbar input
  --border-radius: 3rem; //	Border radius of the searchbar input
  --box-shadow: 0 0 0 .1rem var(--q-app--border-color); // Box shadow of the searchbar input
  --cancel-button-color: var(--ion-color-gray40); //	Color of the searchbar cancel button
  --clear-button-color: var(--ion-color-gray40); //	Color of the searchbar clear button
  // --color	Color of the searchbar text
  --icon-color: var(--ion-color-gray40);	// Color of the searchbar icon
  // --placeholder-color	Color of the searchbar placeholder
  // --placeholder-font-style	Font style of the searchbar placeholder
  // --placeholder-font-weight	Font weight of the searchbar placeholder
  // --placeholder-opacity	Opacity of the searchbar placeholder
  font-weight: 500;
  padding: 0;

}
ion-searchbar.searchbar-has-focus {
  --border-color: var(--ion-color-highlight) !important;
  --box-shadow: 0 0 0 1px var(--ion-color-highlight) !important;
}
.borderBottom20{
  border-bottom: 1px solid var(--ion-color-gray20);
}
.borderBottom05{
  border-bottom: 1px solid var(--ion-color-gray05);
}
.patient_txt{
  background-color: var(--ion-color-gray100);
}

.k-overlay{
  opacity: 0.2 !important;
}

.hide_desktop_patient_at_home{
  display: none !important;
}

.hide_tablet_patient_at_home{
  display: none !important;
}

.hide_mobile_patient_at_home{
  display: block !important;
}

@media only screen and (max-width: 991px) and (min-width: 767px){
  .tabletFont{
    font-size: var(--q-font-size-h3) !important;
    font-weight: var(--q-font-weight-medium) !important;
    font-family: var(--q-secondary-font-family) !important;
    line-height: 29.26px !important;
  }
}

@media(max-width:991px){

  .hide_tablet_patient_at_home{
    display: none !important;
  }
  .hide_desktop_patient_at_home{
    display: block !important;
  }
  .hide_mobile_patient_at_home{
    display: block !important;
  }
}
@media(max-width:767px){
  // .tabletFont{
  //   font-size: var(--q-large-20-font-size) !important;
  //   font-weight: var(--q-font-weight-medium) !important;
  //   font-family: var(--q-secondary-font-family) !important;
  //   line-height: 24.38px !important;
  // }
  // .hide_tablet_patient_at_home{
  //   display: block !important;
  //   width: 100%;
  // }
  .hide_desktop_patient_at_home{
    display: block !important;
  }
  .hide_mobile_patient_at_home{
    display: none !important;
  }
}
ion-footer{
  background-color: var(--ion-header-bg-color);
}
.no-wrap{
  white-space: nowrap;
}
.listMain{
  overflow-y: auto;
  height: -webkit-calc(100vh - 140px);
  height: -moz-calc(100vh - 140px);
  height: calc(100vh - 140px);

  height: -webkit-calc(100dvh - 140px);
  height: -moz-calc(100dvh - 140px);
  height: calc(100dvh - 140px);

  height: -webkit-calc(100svh - 140px);
  height: -moz-calc(100svh - 140px);
  height: calc(100svh - 140px);

}
.patient_list_main.listMain{
  overflow-y: auto;
  height: -webkit-calc(100vh - 240px);
  height: -moz-calc(100vh - 240px);
  height: calc(100vh - 240px);

  height: -webkit-calc(100dvh - 240px);
  height: -moz-calc(100dvh - 240px);
  height: calc(100dvh - 240px);

  height: -webkit-calc(100svh - 240px);
  height: -moz-calc(100svh - 240px);
  height: calc(100svh - 240px);

}
ion-navbar.toolbar.toolbar-ios.statusbar-padding,
ion-navbar.toolbar-ios ion-title.title-ios,
ion-toolbar.toolbar.toolbar-ios.statusbar-padding,
ion-toolbar.toolbar-ios ion-title.title-ios {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

ion-app{
  margin-top: env(safe-area-inset-top);
// margin-bottom: env(safe-area-inset-bottom)
}
.borderAllGray20{
  border:1px solid var(--ion-color-gray20)
}
.border-radius05{
  border-radius: 5px;
}
.bg-gray05{
  background-color: var(--ion-color-gray05);
}
.borderBottomGray05{
  border-bottom:1px solid var(--ion-color-gray05)
}
.borderTopGray20{
  border-top:1px solid var(--ion-color-gray20)
}
.borderBottomGray20{
  border-bottom:1px solid var(--ion-color-gray20)
}
.pre-registration-patient-footer{
  .filled-clear-btn{
    background-color:var(--ion-header-bg-color) !important;
  }
}
.clinic-dashboard-main{
  .showOnPreRegister{
    display: none;
  }
  .hideOnPreRegister{
    display: flex;
  }
}
.pre-registration-patient-main{
  .showOnPreRegister{
    display: block;
  }
  .hideOnPreRegister{
    display: none;
  }
}
.form-text-input{
  ::placeholder{
      font-size: 16px;
      font-weight: 400;
      color: var(--ion-color-gray60);
      
  }
}

::ng-deep{
  .k-input-solid{
    ::placeholder{
      font-style: italic !important;;
      font-size: 14px !important;
      font-weight: 400 !important;;
      color: #9DAAB0 !important;;
    }
  }
}

.has-focus.sc-ion-textarea-ios-h textarea.sc-ion-textarea-ios{
  caret-color: #000 !important;
}
.text-ellipsis{
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
::ng-deep{
  .k-input-inner{
      padding: 9px 10px 8px 10px !important;
  }
  .k-dialog-actions{
    border-width: 0 !important;
  }
}
:root {
  --app-max-width: 1024px;
}

.main_container {
  width: 100%;
  max-width: var(--app-max-width);
  margin: auto;
  border-left:1px solid var(--ion-color-gray20);
  border-right:1px solid var(--ion-color-gray20);
  background-color: #fff;
}
body{
  background-color: #ECEFF7;
  @media(max-width:767px){
    background-color: #fff;
  }
}
.main-div{
  background-color: #fff;
}
.header-md{
  box-shadow: none !important;
}
.footer-md{
  box-shadow: none !important;
}
.footer-md::before{
  background-image: none !important;
}
ion-footer.bg-white{
  background-color: var(--ion-color-white);
}
.scroll_main{
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  max-height: 100svh;
  max-height: 100dvh;
  min-height: 400px;
}

.scroll_body{
  flex-grow: 1;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.boxShadowBottom{
  box-shadow: 0 0px 10px 0px #d5d5d5, 0 1px 11px 0px #d5d5d5;
  position: relative;
  z-index: 999;
}
.mid-header-section {
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}
.ion-color.sc-ion-textarea-md-h{
  width: 100% !important;
}
.line-clamp-2{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}
.flex-wrap{
  flex-wrap: wrap;
}
.white-space-wrap{
  white-space: nowrap;
}
.audit_report_header{
  .dashboard_name{
      text-align: center !important;
      width: 33% !important;
      ion-text{
          display: block !important;
      }
  }
  .profile{
    width: 33% !important;
  }
}

ion-app.IosDevice {
  @media(max-width:575px){
    .patient_schedule_main{
      .scroll_body{
        max-height: calc(100vh - 130px);
        max-height: calc(100dvh - 130px);
        max-height: calc(100svh - 130px);
      }
    }
  }
}

ion-app.androidDevice {
  @media(max-width:575px){
    .patient_schedule_main{
      .scroll_body{
        max-height: calc(100vh - 130px);
        max-height: calc(100dvh - 130px);
        max-height: calc(100svh - 130px);
      }
    }
  }
}

.overflowY{
  overflow-y:  auto;
}
.fixedBottom{
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
}

.lg-popup-content{
  @media (min-height: 1201px) {
    max-height: 80vh;
  }

  /* Styles for extra large devices */
  @media (min-height: 1025px) and (max-height: 1200px) {
      max-height: 80vh;
  }

  /* Styles for large devices */
  @media (min-height: 769px) and (max-height: 1024px) {
      max-height: 80vh;
      @media (max-width:600px){
          max-height: 65vh;
      }
  }

  /* Styles for medium devices */
  @media (min-height: 481px) and (max-height: 768px) {
      max-height: 80vh;
      @media (max-width:600px){
          max-height: 66vh;
      }
  }

  @media (min-height: 320px) and (max-height: 480px) {
      max-height: 40vh;
  }
}

.md-popup-content{
  @media (min-height: 1201px) {
      max-height: 40vh;
  }

  /* Styles for extra large devices */
  @media (min-height: 1025px) and (max-height: 1200px) {
      max-height: 48vh;
  }

  /* Styles for large devices */
  @media (min-height: 769px) and (max-height: 1024px) {
      max-height: 55vh;
      @media (max-width:600px){
          max-height: 45vh;
      }
  }

  /* Styles for medium devices */
  @media (min-height: 481px) and (max-height: 768px) {
      max-height: 70vh;
      @media (max-width:600px){
          max-height: 45vh;
      }
  }

  @media (min-height: 376px) and (max-height: 480px) {
      max-height: 35vh;
  }
  @media (min-height: 320px) and (max-height: 375px) {
      max-height: 35vh;
  }
}
.borderTop20{
  border-top: 1px solid var(--ion-color-gray20);
}
.word-break{
  word-break: break-all;
}


//Toaster Styles
.toast-info, .toast-error, .toast-success, .toast-warning{
  box-shadow: none !important;
}
.toast-container .ngx-toastr{
  padding: 10px 10px 10px 40px!important;
  min-width:300px!important;
  max-width: 420px!important;
  width: 100%!important;
  border-radius: 10px!important;

  @media(max-width:767px){
  max-width: 300px!important;
  }
}
.toast-message{
  width: 95%;
  font-size: 14px;
  font-weight: 400;
  margin: 0 auto !important;;
}
.toast-container .toast-title {
  width: 85%;
  font-size:16px!important;
  font-weight: 700;
}
// .toast-info {
//   background-color: var(--ion-color-alert-Primary100) !important;
//   color: var(--ion-color-white);
//   background-image: url("../../assets/svg/Info-white.svg")!important;
//   background-position: 10px 10px!important;

// }
// .toast-error {
//   background-color: #d32f2f !important;
//   color: var(--ion-color-white);
//   background-image: url("../../assets/svg/Info-white.svg")!important;
//   background-position: 10px 10px!important;
// }
// .toast-success {
//   background-image: url("../../assets/svgtick-circle.svg")!important;
//   background-color: var(--ion-color-alert-success100) !important;
//   color:var(--ion-color-black)!important;
//   background-position: 10px 10px!important;
//  // background-image: url("../../assets/svg/check-circle.svg");
// }

#toast-container{
  .toast-warning {
    background-color: #d32f2f !important;
    color: var(--ion-color-white)!important;
    background-position: 10px 10px!important;
  }
  button.toast-close-button  {
    position: absolute!important;
    border: 1px solid var(--ion-color-white)!important;
    padding: 0px 5px 1px 5px !important;
    border-radius: 5px!important;
    height: 21px !important;
    width: 21px !important;
    font-size: 18px;
    font-weight: 400;
    transform: translate(-0%, -50%);
    top: 50%!important;
    right: 10px!important;
    text-shadow: none!important;
  }
  .toast-close-button:hover, .toast-close-button:focus {
    color :var(--ion-color-white)!important;
    text-decoration: none!important;
    cursor: pointer!important;
    opacity:10!important;
  }
  .toast-warning {
    button.toast-close-button  {
    border: 1px solid var(--ion-color-white)!important;
        color: var(--ion-color-white)!important;
    }
    .toast-close-button:hover{
      color: var(--ion-color-white)!important;
    }
  }
  .toast-success {
    button.toast-close-button  {
      display: none!important;
    }
  }
}





  
.u-WrapText{
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}
.searchbar-input.sc-ion-searchbar-md{
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}
::ng-deep{
  kendo-textbox{
    ::placeholder{
      font-size: 16px;
      font-style: italic;
      font-weight: 300;
      color: #9DAAB0;
    }
  }
  
 
}

.grid-text-ellipse {
  white-space: nowrap !important; 
  overflow: hidden !important;
  text-overflow: ellipsis !important
}


//text classes
.u-truncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.u-truncateText--none {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}
.u-wrapText {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
}
.u-breakText--all {
  white-space: normal;
  word-break: break-all;
}
.u-text-decoration--line-through {
  text-decoration: line-through;
}
.u-center {
  align-items: center;
  justify-content: center;
}


.mobile-scroll{
  max-height: calc(100vh - 230px);
  max-height: calc(100dvh - 230px);
  max-height: calc(100svh - 230px);
}

.mobileShow{
  display: none;
  @media(max-width:575px){
    display: block;
  }
}

.popup_header{
  @media(max-width:575px){
    padding: 10px 20px !important;
  }
}
.line-clamp-1{
  display: -webkit-box;
  width: 100%;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}