.form-text-input{
    ::placeholder{
        font-style: italic;
        font-size: 16px;
        font-weight: 300;
        color: #9DAAB0;
        
    }
}

kendo-combobox, kendo-textarea {
  ::placeholder{
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    color: #9DAAB0;
  }
}
::placeholder{
    font-style: italic;
    font-size: 16px;
    font-weight: 300;
    color: #9DAAB0;
}
.patient_schedule_main,
.schedule_appointment_time_main{
    ::placeholder{
        font-style: italic !important;
        font-size: 20px !important;
        font-weight: 400;
        color:#738289 !important;
    }
}
.clinic_signin_form_main{
    ::placeholder{
        font-style: normal !important;
        font-size: 20px !important;
        font-weight: 400;
    }
}
.confirm_patient_popup{
    ::placeholder{
        font-style: normal !important;
        font-size: 24px !important;
        font-weight: 400;
    }
}


.text-ellipse {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    // width: 100% !important;
    // max-width: 300px !important;
    display: block !important;
}

/* width */
::-webkit-scrollbar {
    height: 5px !important;
  }

  ::ng-deep{
    .refered_provider_grid{
        .k-pager-numbers{
            display: none !important;
       }
    }
    
}

.k-tooltip {
    box-shadow: 0 0.2rem 0.4rem -0.1rem rgba(0, 0, 0, 0.2), 0 0.4rem 0.5rem 0 rgba(0, 0, 0, 0.14), 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.12);
    background-color: rgba(39, 49, 56, 0.80) !important;
  }
  .k-tooltip {
    padding: 5px !important;
    max-width: 375px;
    width: auto;
    word-wrap: break-word;
    .k-callout{
        color: rgba(39, 49, 56, 0.80) !important;
    }
  }