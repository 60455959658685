:root {
  /* Primary */
  --ion-color-primarydk: #00274e;
  --ion-color-primary100: #257099;
  --ion-color-primary80: #518dad;
  --ion-color-primary60: #7ca9c2;
  --ion-color-primary40: #a8c6d6;
  --ion-color-primary20: #d3e2eb;
  --ion-color-primary :#007fc5;

  // Secondary
  --ion-color-secondarydk: #a03f1d;
  --ion-color-secondary100: #e96127;
  --ion-color-secondary80: #ed8152;
  --ion-color-secondary60: #f2a07d;
  --ion-color-secondary40: #f6c0a9;
  --ion-color-secondary20: #fbdfd4;

  // Gray
  --ion-color-gray100: #273138;
  --ion-color-gray80: #4b5961;
  --ion-color-gray60: #738289;
  --ion-color-gray40: #9daab0;
  --ion-color-gray20: #ccd4d8;
  --ion-color-gray05: #f4f4f5;
  --ion-color-gray02:#4F4F4F;
  --ion-color-gray01: #F3F3F3;


  // System Colors
  --ion-color-black: #000000;
  --ion-color-white: #ffffff;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-highlight: #007fc5;
  --ion-color-highlightBG: #F2F9FC;

  // Alert Colors
  --ion-color-success100: #4caf50;
  --ion-color-success60: #94cf96;
  --ion-color-success20: #dbefdc;

  --ion-color-warning100: #f9a825;
  --ion-color-warning60: #fbcb7c;
  --ion-color-warning20: #feeed3;

  --ion-color-error100: #d32f2f;
  --ion-color-error80:#f31700;
  --ion-color-error60: #e58282;
  --ion-color-error20: #f6d5d5;

  --ion-color-info100: #2685ca;
  --ion-color-info60: #7db6df;
  --ion-color-info20: #7db6df;

  --ion-button-bg-color: #0677B5;
  --ion-header-bg-color: #F0F3F5;

  --ion-blue-text-color: #2D6280;
  --ion-HLblue-color:#0677B5;
  --ion-green-text-color:#45A849;
  --ion-text-color: #333333;
  --ion-background-color:#F9FAFB;

  //Link color
  --ion-color-link: #0D7DBA;


  --ion-color-navyBlue:#0F2679;
}


 /* Primary color classes*/
.ion-color-primarydk {
  color: var(--ion-color-primarydk);
}
.ion-color-primary100 {
  color: var(--ion-color-primary100);
}
.ion-color-primary80 {
  color: var(--ion-color-primary80);
}

.ion-color-primary60 {
  color: var(--ion-color-primary60);
}
.ion-color-primary40 {
  color: var(--ion-color-primary40);
}
.ion-color-primary20 {
  color: var(--ion-color-primary20);
}

  // Secondary color classes
.ion-color-secondarydk {
  color: var(--ion-color-secondarydk);
}
.ion-color-secondary100 {
  color: var(--ion-color-secondary100);
}
.ion-color-secondary80 {
  color: var(--ion-color-secondary80);
}
.ion-color-secondary60 {
  color: var(--ion-color-secondary60);
}
.ion-color-secondary40 {
  color: var(--ion-color-secondary40);
}
.ion-color-secondary20 {
  color: var(--ion-color-secondary20);
}

//gray color classes
.ion-color-gray100 {
  color: var(--ion-color-gray100);
}
.ion-color-gray80 {
  --ion-color-base: var(--ion-color-gray80);
}
.ion-color-gray60 {
  color: var(--ion-color-gray60);
}
.ion-color-gray40 {
  color: var(--ion-color-gray40);
}
.ion-color-gray20 {
  color: var(--ion-color-gray20);
}
.ion-color-gray05 {
  color: var(--ion-color-gray05);
}
.ion-color-gray02{
  color: var(--ion-color-gray02);
}
.ion-color-gray01 {
  color: var(--ion-color-gray01);
}

//system color classes
.ion-color-black {
  color: var(--ion-color-black);
}
.ion-color-white {
  color: var(--ion-color-white);
  --ion-color-shade: var(--ion-color-white-shade);
}
.ion-color-highlight {
  --ion-color-base: var(--ion-color-highlight);
}

//alert color classes
.ion-color-success100 {
  color: var(--ion-color-success100);
}
.ion-color-success60 {
  color: var(--ion-color-success60);
}
.ion-color-success20 {
  color: var(--ion-color-success20);
}
.ion-color-warning100 {
  --ion-color-base: var(--ion-color-warning100);
}
.ion-color-warning60 {
  color: var(--ion-color-warning60);
}
.ion-color-warning20 {
  color: var(--ion-color-warning20);
}
.ion-color-error100 {
  color: var(--ion-color-error100);
}
.ion-color-error80 {
  color: var(--ion-color-error80);
}
.ion-color-error60 {
  color: var(--ion-color-error60);
}
.ion-color-error20 {
  color: var(--ion-color-error20);
}
.ion-color-info100 {
  color: var(--ion-color-info100);
}
.ion-color-info60 {
  color: var(--ion-color-info60);
}
.ion-color-info20 {
  color: var(--ion-color-info20);

}
.text-HLblue-color{
  color:var(--ion-HLblue-color);
}

.text-gray80-color{
  color: var(--ion-color-gray80);
}
.text-green-color{
  color:var(--ion-green-text-color) ;
}
.text-blue-color {
  color: var(--ion-blue-text-color);
}
.text-color {
  color: var(--ion-text-color);
}
.link-color{
  color: var(--ion-button-bg-color);
}
.ion-header-bg-color{
  background-color:var(--ion-header-bg-color);
}
.ion-background-color{
  background-color: var(--ion-background-color);
  }
.ion-bg-unfocused-color{
  background-color:var(--ion-background-color);
}
.bg-cool-gray{
  background-color: #F0F3F5;
}



.ion-color-link {
  color: var(--ion-color-link);
}

.ion-color-navyBlue{
  --ion-color-base: var(--ion-color-navyBlue);
}